import React from "react";
import PizzaOvenImg from "../../assets/pizza-oven-1.jpg";

function Content() {
  return (
    <section className="bg-white text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-2xl font-bold sm:text-4xl">About Us</h1>
        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={PizzaOvenImg}
              alt="Pizza oven with a sign that says 'Keep It Simple' above it."
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>

          <div className="lg:py-16">
            <article className="space-y-4 ">
              <p>
                Welcome to KIS Pizza & Coffee Bar in Crossmaglen, where we offer
                a delicious menu of pizzas, coffee, and traybakes in a warm and
                inviting atmosphere. Our pizzas are made with the freshest
                ingredients, hand-tossed crusts, and baked to perfection in our
                wood-fired oven for an authentic flavor. From classic
                Margheritas to spicy pepperonis, and even custom creations, we
                have something to suit every taste. In addition to our delicious
                pizzas, we also offer a range of specialty coffee drinks made
                with high-quality beans and expertly crafted by our baristas.
                And if you're in the mood for something sweet, try one of our
                traybakes, made with the finest ingredients and baked fresh
                every day.
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
