import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/logo.png";

const Navbar = () => {
  useEffect(() => {
    window.addEventListener("hashchange", handleScrollTop);
    return () => window.removeEventListener("hashchange", handleScrollTop);
  }, []);

  const [nav, setNav] = useState(false);

  const handleClick = () => {
    setNav(!nav);
    handleScrollTop();
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section id="navbar">
      <div className="w-full h-[100px] flex justify-between items-center px-4 bg-primary text-white fixed top-0 left-0 z-50">
        <div>
          <img src={Logo} alt="logo" style={{ width: "100px" }} />
        </div>

        {/* menu */}
        <ul className="hidden md:flex flex-row">
          <li className="mx-4 hover:text-secondary duration-300">
            <NavLink to="/" onClick={handleScrollTop}>
              Home
            </NavLink>
          </li>
          <li className="mx-4 hover:text-secondary duration-300">
            <NavLink to="/Story" onClick={handleScrollTop}>
              Our Story
            </NavLink>
          </li>
          <li className="mx-4 hover:text-secondary duration-300">
            <NavLink to="/Pizza" onClick={handleScrollTop}>
              Pizza
            </NavLink>
          </li>
          <li className="mx-4 hover:text-secondary duration-300">
            <NavLink to="/Traybakes" onClick={handleScrollTop}>
              Traybakes
            </NavLink>
          </li>
          <li className="mx-4 hover:text-secondary duration-300">
            <NavLink to="/Coffee" onClick={handleScrollTop}>
              Coffee
            </NavLink>
          </li>
          <li className="mx-4 hover:text-secondary duration-300">
            <NavLink to="/Contact" onClick={handleScrollTop}>
              Contact
            </NavLink>
          </li>
        </ul>

        {/* hamburger menu */}
        <div onClick={handleClick} className="md:hidden z-10">
          {!nav ? <FaBars /> : <FaTimes />}
        </div>

        {/* mobile menu */}
        <ul
          className={
            !nav
              ? "hidden"
              : "fixed top-0 left-0 w-full h-screen bg-primary flex flex-col justify-center items-center"
          }
        >
          <li className="py-6 text-2xl hover:text-secondary duration-300">
            <NavLink to="/" onClick={handleClick}>
              Home
            </NavLink>
          </li>
          <li className="py-6 text-2xl hover:text-secondary duration-300">
            <NavLink to="/Story" onClick={handleClick}>
              Our Story
            </NavLink>
          </li>
          <li className="py-6 text-2xl hover:text-secondary duration-300">
            <NavLink to="/Pizza" onClick={handleClick}>
              Pizza
            </NavLink>
          </li>
          <li className="py-6 text-2xl hover:text-secondary duration-300">
            <NavLink to="/Traybakes" onClick={handleClick}>
              Traybakes
            </NavLink>
          </li>
          <li className="py-6 text-2xl hover:text-secondary duration-300">
            <NavLink to="/Coffee" onClick={handleClick}>
              Coffee
            </NavLink>
          </li>
          <li className="py-6 text-2xl hover:text-secondary duration-300">
            <NavLink to="/Contact" onClick={handleClick}>
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Navbar;
