import React from "react";
import Navbar from "./components/general/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Story from "./pages/Story";
import Pizza from "./pages/Pizza";
import Traybakes from "./pages/Traybakes";
import Contact from "./pages/Contact";
import Coffee from "./pages/Coffee";
import PageNotFound from "./pages/PageNotFound";
import Footer from "./components/general/Footer";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Story" element={<Story />} />
        <Route exact path="/Pizza" element={<Pizza />} />
        <Route exact path="/Traybakes" element={<Traybakes />} />
        <Route exact path="/Coffee" element={<Coffee />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
