import React from "react";

/* Component to display a single menu item */

const MenuItem = ({
  name,
  ingredients,
  sitInPrice9,
  sitInPrice12,
  takeawayPrice9,
  takeawayPrice12,
}) => {
  return (
    <div className="bg-white p-6">
      <h1 className="text-xl font-bold mb-2">{name}</h1>
      <p className="text-gray-700">{ingredients}</p>
      <table className="mt-4 w-full">
        <thead>
          <tr>
            <th className="text-left">Size</th>
            <th className="text-left">Sit-in</th>
            {takeawayPrice9 !== undefined || takeawayPrice12 !== undefined ? (
              <th className="text-left">Takeaway</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-2">9"</td>
            <td className="py-2">£{sitInPrice9}</td>
            {takeawayPrice9 !== undefined ? (
              <td className="py-2">£{takeawayPrice9}</td>
            ) : null}
          </tr>
          <tr>
            <td className="py-2">12"</td>
            <td className="py-2">£{sitInPrice12}</td>
            {takeawayPrice12 !== undefined ? (
              <td className="py-2">£{takeawayPrice12}</td>
            ) : null}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MenuItem;
