import React, { useState } from "react";
import MugsImg from "../../assets/coffee-mugs.jpg";

const Reviews = () => {
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  const handleTransitionEnd = () => {
    setIsTransitionComplete(true);
  };

  return (
    <section className="bg-white text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-2xl font-bold sm:text-4xl">Leave Us A Review</h1>
        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <figure className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={MugsImg}
              alt="Two cups of coffee, ready to serve at the counter."
              className="absolute inset-0 w-full h-full object-cover"
            />
          </figure>
          <article className="lg:py-16 text-center md:text-left space-y-4">
            <p className="mb-6">
              We value all our customers, and strive to give them a positive
              experience at our cafe. If you have a moment, we would greatly
              appreciate it if you could leave a review on Google to let others
              know about your visit. Your feedback helps us improve our services
              and provide the best experience for all of our customers. Thank
              you for your support!
            </p>
            <div className="mt-8 text-center md:text-start">
              <button
                type="button"
                className={`block mx-auto w-auto rounded-lg bg-secondary hover:bg-primary duration-300 px-5 py-3 text-sm text-white md:inline-block md:w-auto ${
                  isTransitionComplete ? "border-2 border-secondary" : ""
                }`}
                onTransitionEnd={handleTransitionEnd}
                style={{
                  transitionProperty: "background-color",
                  transitionDuration: "300ms",
                }}
              >
                <a
                  href="https://g.page/r/CVnl3eR1Xe_bEBM/review"
                  target="_blank"
                  rel="noreferrer"
                >
                  Leave Us A Review
                </a>
              </button>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
