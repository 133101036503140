import React from "react";
import Content from "../components/traybakes/Content";
import Gallery from "../components/traybakes/Gallery";
import { Helmet } from "react-helmet";

function Traybakes() {
  return (
    <div>
      <Helmet>
        <title>Our Traybakes - KIS Pizza & Coffee Bar, Crossmaglen</title>
        <meta
          name="description"
          content="We have a wide selection of traybakes all of which are HOMEMADE,
      these include our massive donut selection, KIS caramel squares,
      brownies, cookies, cinnamon rolls, scones, criossants, and
      protein balls."
        />
        <meta
          name="keywords"
          content="coffee journey, cinnamon rolls, scones, caramel squares, cookies, Egg, experience, coffee shops, Ireland, new ideas, conversations, amazing little shop, customers, Crossmaglen, successful launch, wood fired pizza place, Nanny Hughes's Pizzeria, Nanny, accomplished baker, pizza business, world, Italy, Brooklyn, pizzerias, KIS, relocated, rebranding, keep it simple, simplicity, quality over quantity, coffee, pizza, donuts, traybakes"
        />
      </Helmet>
      <Content />
      <Gallery />
    </div>
  );
}

export default Traybakes;
