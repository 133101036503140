import React from "react";
import BooksImg from "../../assets/books-6.JPG";
import Accordion from "../general/Accordion";

function Info() {
  return (
    <section className="bg-primary">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-2xl text-center md:text-left text-secondary font-bold sm:text-4xl">
          Get In Touch
        </h1>
        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="lg:py-16">
            <Accordion title={"Opening Hours"}>
              <ul>
                <li>Monday and Tuesday: 07:00 - 16:00</li>
                <li>Wednesday and Thursday: 07:00 - 21:00</li>
                <li>Friday: 07:00 - 22:00</li>
                <li>Saturday: 08:00 - 22:00</li>
                <li>Sunday: 10:00 - 21:00</li>
              </ul>
            </Accordion>
            <Accordion title={"Where To Find Us"}>
              <p>
                Situated just 10 minutes of the M1 corridor between Belfast and
                Dublin, 15 minutes from Dundalk and 20 minutes from Newry city,
                KIS is extremely well located for all coffee and pizza lovers.
              </p>
            </Accordion>
            <Accordion title={"Contact"}>
              <p>
                Contact us using the details on our Contact page. Please use our
                email for general enquiries and our phone number for urgent
                queries.
              </p>
            </Accordion>
          </div>
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={BooksImg}
              alt="Two books on a shelf, one titled 'Perfect Pan Pizza' and the other 'The Coffee Book'."
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info;
