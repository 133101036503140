import React from "react";

const MenuItem = ({ name, price }) => {
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 1,
  });

  return (
    <div className="bg-white p-6">
      <h1 className="text-xl font-bold mb-2">{name}</h1>
      <table className="mt-4 w-full">
        <thead>
          <tr>
            <th className="text-left">Price</th>
            <th className="text-left">Sit-in</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-2">{formatter.format(price)}</td>
            <td className="py-2">{formatter.format(price + 0.5)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MenuItem;
