import React from "react";
import Button from "../general/Button";

import { Suspense } from "react";
import Video from "./Video";

const Hero = () => {
  return (
    <section
      id="hero"
      className="mt-24 bg-primary sm:grid sm:grid-cols-2 sm:gap-8 text-center md:text-left"
    >
      <div className="p-8 md:p-12 lg:px-16 lg:py-24 flex flex-col justify-center">
        <div className="max-w-xl mx-auto">
          <h1 className="text-3xl md:text-4xl text-secondary font-bold mb-4 md:mb-6">
            Experience the Perfect Blend of Pizza and Coffee at KIS Pizza &
            Coffee Bar
          </h1>
          <h2 className="hidden text-md md:block text-white mb-6">
            At KIS Pizza & Coffee Bar, we offer the perfect combination of
            delicious pizza and aromatic coffee in the heart of Crossmaglen. Our
            pizzas are made from the freshest ingredients and are cooked to
            perfection in our wood-fired oven, while our coffee is sourced from
            the finest beans and expertly brewed by our skilled baristas.
            Whether you're looking for a quick bite on the go or a cozy place to
            sit and relax with friends, KIS Pizza & Coffee Bar is the perfect
            destination.
          </h2>

          <Button text="Read Our Story" href={"/Story"} />
        </div>
      </div>

      <div className="relative h-56 sm:h-full overflow-hidden">
        <Suspense fallback={<div>Loading...</div>}>
          <Video />
        </Suspense>
      </div>
    </section>
  );
};

export default Hero;
