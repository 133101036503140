import React from "react";
import Content from "../components/coffee/Content";
import Menu from "../components/coffee/Menu";
import { Helmet } from "react-helmet";

function Coffee() {
  return (
    <div>
      <Helmet>
        <title>Our Coffee - KIS Pizza & Coffee Bar, Crossmaglen</title>
        <meta
          name="description"
          content="Our house coffee is made up of 4 different beans, 2 from
                  Cerrado Grande & Fazenda Londrina, Brazil, 1 from El Morito,
                  Guatemala, and 1 from El Jaguar, Mexico. It’s a mild roast,
                  easy to drink no matter if you’re an espresso drinker or
                  cappuccino lover. We loved it from the very first sip, we
                  hope you do also."
        />
        <meta
          name="keywords"
          content="coffee journey, cinnamon rolls, scones, caramel squares, cookies, Egg, experience, coffee shops, Ireland, new ideas, conversations, amazing little shop, customers, Crossmaglen, successful launch, wood fired pizza place, Nanny Hughes's Pizzeria, Nanny, accomplished baker, pizza business, world, Italy, Brooklyn, pizzerias, KIS, relocated, rebranding, keep it simple, simplicity, quality over quantity, coffee, pizza, donuts, traybakes"
        />
      </Helmet>
      <Content />
      <Menu />
    </div>
  );
}

export default Coffee;
