import React from "react";
import Hero from "../components/home/Hero";
import Content from "../components/home/Content";
import Reviews from "../components/home/Reviews";
import Info from "../components/home/Info";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <title>KIS Pizza & Coffee Bar, Crossmaglen</title>
        <meta
          name="description"
          content="At KIS Pizza & Coffee Bar, we offer the perfect combination of
          delicious pizza and aromatic coffee in the heart of Crossmaglen. Our
          pizzas are made from the freshest ingredients and are cooked to
          perfection in our wood-fired oven, while our coffee is sourced from
          the finest beans and expertly brewed by our skilled baristas.
          Whether you're looking for a quick bite on the go or a cozy place to
          sit and relax with friends, KIS Pizza & Coffee Bar is the perfect
          destination."
        />
        <meta
          name="keywords"
          content="coffee journey, cinnamon rolls, scones, caramel squares, cookies, Egg, experience, coffee shops, Ireland, new ideas, conversations, amazing little shop, customers, Crossmaglen, successful launch, wood fired pizza place, Nanny Hughes's Pizzeria, Nanny, accomplished baker, pizza business, world, Italy, Brooklyn, pizzerias, KIS, relocated, rebranding, keep it simple, simplicity, quality over quantity, coffee, pizza, donuts, traybakes"
        />
      </Helmet>
      <Hero />
      <Content />
      <Info />
      <Reviews />
    </div>
  );
}

export default Home;
