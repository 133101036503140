import React, { useState } from "react";

const Accordion = ({ title, children }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <button
        type="button"
        onClick={toggleAccordion}
        className="w-full text-left p-4 text-lg font-semibold text-white bg-primary hover:text-secondary duration-300 focus:outline-none"
      >
        <span className="flex justify-between items-center">
          {title}
          <span
            className={`transition-transform transform ${
              isAccordionOpen ? "rotate-180" : ""
            }`}
          >
            &#8250;
          </span>
        </span>
      </button>
      {isAccordionOpen && (
        <div className="p-4 text-white">
          <p>{children}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
