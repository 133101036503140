import React from "react";
import { FaPhone } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import SignImg from "../../assets/sign-outside.jpg";

function Content() {
  return (
    <section className="bg-primary text-white mt-24 text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-secondary text-3xl font-bold sm:text-4xl">
          Contact Us
        </h1>
        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-auto overflow-hidden sm:h-80 lg:h-full">
            <div className="lg:py-16">
              <p>
                Our contact details are outlined below. We aim to respond to
                emails as quickly as possible, but if you have any urgent
                queries, please call us.
              </p>
              <h1 className="text-xl font-bold text-secondary mt-6">
                Where to find us
              </h1>
              <address className="mt-2 not-italic">
                45 Cardinal O'Fiaich Square, Crossmaglen, Newry BT35 9HQ, United
                Kingdom
              </address>
              <h1 className="text-xl font-bold text-secondary mt-6">
                How to contact us
              </h1>
              <div className="flex flex-row items-center justify-center md:justify-start mt-2">
                <FaPhone className="mr-2 text-secondary" />
                <a href="tel:+4402830860039">028 3086 0039</a>
              </div>
              <div className="flex flex-row items-center justify-center md:justify-start mt-2">
                <HiOutlineMail className="mr-2 text-secondary" />
                <a href="mailto:kispizzacoffee@gmail.com">
                  kispizzacoffee@gmail.com
                </a>
              </div>
              <h1 className="text-xl font-bold text-secondary mt-6">Socials</h1>
              <div className="flex flex-row items-center justify-center md:justify-start mt-2 gap-1">
                <a href="https://www.instagram.com/kis_pizza_coffee/" target="_blank">
                  <FaFacebook className="mr-2 text-xl hover:text-secondary duration-300" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100086121978464" target="_blank">
                  <FaInstagram className="mr-2 text-xl hover:text-secondary duration-300" />
                </a>
              </div>
            </div>
          </div>
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={SignImg}
              alt="KIS Pizza & Coffee Bar, Crossmaglen front sign."
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
