import React from "react";
import AboutImg from "../../assets/about-us-2.jpg";

function Content() {
  return (
    <section className="bg-white mt-24 text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-3xl font-bold sm:text-4xl">Our Story</h1>
        <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-auto overflow-hidden sm:h-80 lg:h-full">
            <div className="lg:py-16">
              <article className="space-y-4 text-gray-600">
                <p>
                  2017 marked the beginning of our coffee journey opening Egg.
                  With no experience, we set off visiting different coffee shops
                  around Ireland to seek out new ideas and learn from the best.
                  Following countless miles on the road and conversations with
                  Ireland’s best, we’re proud to say that what we came up with
                  turned out to be an amazing little shop which thousands of
                  people enjoyed.
                </p>

                <p>
                  Small, yet quirky Egg, was situated in the heart of
                  Crossmaglen, where we were graced with many wonderful
                  customers, many of which are thankfully still with us today.
                </p>
                <p>
                  Following our successful launch, Egg was on a roll and we were
                  hungry for more… Two years on, we expanded to open our first
                  wood fired pizza place which was then known as ‘Nanny Hughes's
                  Pizzeria’. It was named after my Nanny, who in her own right,
                  was a very accomplished baker.
                </p>
                <p>
                  The pizza business took us all around the world, from the
                  cobbled back streets of Italy to the vibrant streets of
                  Brooklyn visiting some of the most renowned pizzerias in the
                  world. We've met some unbelievable people along the way and no
                  doubt, we’ll meet many more.
                </p>
                <p>
                  Fast forward to 2023.... Cue the KIS era. We’ve relocated our
                  two businesses to the one premises, rebranding to ‘KIS’ which
                  encapsulates our mantra of "keep it simple”. It's a philosophy
                  that has worked for us since the very start of our journey. We
                  believe in simplicity, which for us means doing a handful of
                  things really well and excelling in the fields we’re in.
                  Quality over quantity always.
                </p>
                <p>
                  In addition to our coffee and pizza at KIS, we make fresh
                  donuts and mouth watering traybakes on a daily basis so if you
                  have a sweet tooth, look no further.
                </p>
                <p>
                  Looking forward to the future, we don’t know what exactly lies
                  ahead but what we can say for sure is that we've a few things
                  up our sleeve so watch this space.
                </p>
                <p>
                  Thank you,
                  <br />
                  <span className="text-secondary font-bold">
                    Patrick Mceneaney
                  </span>
                </p>
              </article>
            </div>
          </div>
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={AboutImg}
              alt="Owners of KIS Pizza & Coffee Bar, Crossmaglen"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
