import React from "react";
import MenuItem from "./MenuItem";

function Menu() {
  return (
    <section className="bg-white text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-3xl font-bold sm:text-4xl">Coffee Menu</h1>
        <div className="text-left grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          {/*Menu items go here */}
          <MenuItem name="Espresso" price={2.6} />
          <MenuItem name="Americano" price={2.6} />
          <MenuItem name="Long Black" price={2.6} />
          <MenuItem name="Flat White" price={2.8} />
          <MenuItem name="Latte" price={3.0} />
          <MenuItem name="Cappuccino" price={3.0} />
          <MenuItem name="Macchiato" price={2.8} />
          <MenuItem name="Cortado" price={2.8} />
          <MenuItem name="Mocha" price={3.0} />
          <MenuItem name="Hot Choc" price={2.8} />
          <MenuItem name="Teas" price={1.5} />
        </div>
      </div>
    </section>
  );
}

export default Menu;
