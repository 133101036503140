import React from "react";
import DonutsImg from "../../assets/donuts-1.jpg";

function Content() {
  return (
    <section className="bg-primary mt-24 text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-secondary text-3xl font-bold sm:text-4xl mb-8">
          Indulge in our Freshly Baked Traybakes and Coffee
        </h1>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 items-center justify-center">
          <div className="lg:py-16 text-white">
            <article className="space-y-4">
              <p className="text-lg">
                We have a wide selection of traybakes all of which are HOMEMADE,
                these include our massive donut selection, KIS caramel squares,
                brownies, cookies, cinnamon rolls, scones, criossants, and
                protein balls.
                <br />
                We take great pride in the fact we make all our products in
                house, and we are continuously looking at new ideas to add to
                our treat menu so keep a close eye on our social media for all
                the exciting new ideas coming.
              </p>
            </article>
          </div>
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={DonutsImg}
              alt="Freshly made donuts, with options including Oreo, Biscoff, sprinkles, and more."
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
