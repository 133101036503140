import React from "react";
import KISVideo from "../../assets/kis-video-edit.mp4";

const Video = () => {
  return (
    <video
      autoPlay
      muted
      playsInline
      preload="auto"
      loop
      id="kisVideo"
      className="absolute inset-0 w-full h-full object-cover"
      poster="../../assets/sign-outside.jpg"
    >
      <source src={KISVideo} type="video/mp4" />
    </video>
  );
};

export default Video;
