import React from "react";
import CookiesImg from "../../assets/cookies.jpg";
import CaramelSquareImg from "../../assets/caramel-square.jpg";
import CinnamonRollImg from "../../assets/cinnamon-rolls.jpg";
import SconesImg from "../../assets/scones.jpg";

function Gallery() {
  return (
    <section className="bg-white text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
          <img
            src={CookiesImg}
            alt="Freshly baked cookies."
            className="w-full h-auto"
          />
          <img
            src={CaramelSquareImg}
            alt="Freshly made caramel squares."
            className="w-full h-auto"
          />
          <img
            src={CinnamonRollImg}
            alt="Freshly baked cinnamon rolls."
            className="w-full h-auto"
          />
          <img
            src={SconesImg}
            alt="Freshly baked scones."
            className="w-full h-auto"
          />
        </div>
      </div>
    </section>
  );
}

export default Gallery;
