import React from "react";
import Content from "../components/pizza/Content";
import Menu from "../components/pizza/Menu";
import { Helmet } from "react-helmet";

function Pizza() {
  return (
    <div>
      <Helmet>
        <title>Our Pizzas - KIS Pizza & Coffee Bar, Crossmaglen</title>
        <meta
          name="description"
          content="At KIS Pizza & Coffee Bar, we take great pride in our
          pizza-making process. Our dough is crafted using the finest
          Italian flour and a complex technique that results in a highly
          digestible dough with intense flavor."
        />
        <meta
          name="keywords"
          content="coffee journey, cinnamon rolls, scones, caramel squares, cookies, Egg, experience, coffee shops, Ireland, new ideas, conversations, amazing little shop, customers, Crossmaglen, successful launch, wood fired pizza place, Nanny Hughes's Pizzeria, Nanny, accomplished baker, pizza business, world, Italy, Brooklyn, pizzerias, KIS, relocated, rebranding, keep it simple, simplicity, quality over quantity, coffee, pizza, donuts, traybakes"
        />
      </Helmet>
      <Content />
      <Menu />
    </div>
  );
}

export default Pizza;
