import React from "react";
import Content from "../components/contact/Content";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contact Us - KIS Pizza & Coffee Bar, Crossmaglen</title>
        <meta
          name="description"
          content="Our contact details are outlined on this page. We aim to respond to emails as quickly as possible, but if you have any urgent queries, please call us."
        />
        <meta
          name="keywords"
          content="coffee journey, cinnamon rolls, scones, caramel squares, cookies, Egg, experience, coffee shops, Ireland, new ideas, conversations, amazing little shop, customers, Crossmaglen, successful launch, wood fired pizza place, Nanny Hughes's Pizzeria, Nanny, accomplished baker, pizza business, world, Italy, Brooklyn, pizzerias, KIS, relocated, rebranding, keep it simple, simplicity, quality over quantity, coffee, pizza, donuts, traybakes"
        />
      </Helmet>
      <Content />
    </div>
  );
}

export default Contact;
