import React from "react";
import Content from "../components/story/Content";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div>
      <Helmet>
        <title>Our Story - KIS Pizza & Coffee Bar, Crossmaglen</title>
        <meta
          name="description"
          content="2017 marked the beginning of our coffee journey opening Egg.
          With no experience, we set off visiting different coffee shops
          around Ireland to seek out new ideas and learn from the best.
          Following countless miles on the road and conversations with
          Ireland’s best, we’re proud to say that what we came up with
          turned out to be an amazing little shop which thousands of
          people enjoyed."
        />
        <meta
          name="keywords"
          content="coffee journey, cinnamon rolls, scones, caramel squares, cookies, Egg, experience, coffee shops, Ireland, new ideas, conversations, amazing little shop, customers, Crossmaglen, successful launch, wood fired pizza place, Nanny Hughes's Pizzeria, Nanny, accomplished baker, pizza business, world, Italy, Brooklyn, pizzerias, KIS, relocated, rebranding, keep it simple, simplicity, quality over quantity, coffee, pizza, donuts, traybakes"
        />
      </Helmet>
      <Content />
    </div>
  );
}

export default About;
