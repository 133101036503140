import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Button = ({ text, href, type }) => {
  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  const handleTransitionEnd = () => {
    setIsTransitionComplete(true);
  };

  return (
    <div className="mt-8 text-center md:text-start">
      <button
        type={type}
        className={`block mx-auto w-auto rounded-lg bg-secondary hover:bg-primary duration-300 px-5 py-3 text-sm text-white md:inline-block md:w-auto ${
          isTransitionComplete ? "border-2 border-secondary" : ""
        }`}
        onTransitionEnd={handleTransitionEnd}
        style={{ transitionProperty: "background-color" }}
      >
        <NavLink to={href}>{text}</NavLink>
      </button>
    </div>
  );
};

export default Button;
