import React from "react";
import CoffeeImg from "../../assets/coffee-beans.jpg";

function Content() {
  return (
    <section className="bg-primary mt-24 text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-secondary text-3xl font-bold sm:text-4xl mb-8">
          Experience the Perfect Cup of Coffee
        </h1>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 items-center justify-center">
          <div className="lg:py-16 text-white">
            <article className="space-y-4">
              <p>
                Our house coffee is made up of 4 different beans, 2 from Cerrado
                Grande & Fazenda Londrina, Brazil, 1 from El Morito, Guatemala,
                and 1 from El Jaguar, Mexico. It’s a mild roast, easy to drink
                no matter if you’re an espresso drinker or cappuccino lover. We
                loved it from the very first sip, we hope you do also.
              </p>

              <p>
                Our roasters are Ristretto Coffee Roasters based in Banbridge
                County Down, since we opened in 2017 we have worked closely with
                the team there and honestly can’t speak highly enough of them,
                their professionalism and attention to detail was exactly what
                we were looking for and right up until today they are at the end
                of phone ready to help and improve what we do.
              </p>
              <p>
                Since opening the new shop we have also introduced a guest
                coffee to the business, this roast will change a few times per
                year, we will source coffees from the best-known roasters
                worldwide.
              </p>
              <p>
                The first guest we have started with was roasted by the famous
                Nomad Roastery in beautiful Barcelona. Keep a close eye on our
                social media for the exciting new guests throughout the year.
              </p>
            </article>
          </div>
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={CoffeeImg}
              alt="Quality coffee beans in a cup."
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
