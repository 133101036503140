import React from "react";
import MenuItem from "./MenuItem";

function Menu() {
  return (
    <section className="bg-white text-center md:text-left">
      <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 class="text-3xl font-bold sm:text-4xl">Pizza Menu</h1>
        <div className="text-left grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
          <MenuItem
            name="MARINARA (NO CHEESE)"
            ingredients="San Marzano Tomato, Garlic, Oregano Basil with Evo oil"
            sitInPrice9="7.0"
            sitInPrice12="10.0"
            takeawayPrice9="5.5"
            takeawayPrice12="9.0"
          />
          <MenuItem
            name="MARGARITA"
            ingredients="San Marzano Tomato, Fior Di Latte, Basil, Parmesan, Evo Oil"
            sitInPrice9="7.0"
            sitInPrice12="10.0"
            takeawayPrice9="5.5"
            takeawayPrice12="9.0"
          />
          <MenuItem
            name="PEPPERONI"
            ingredients="San Marzano Tomato, Fior Di Latte & Pepperoni"
            sitInPrice9="8.0"
            sitInPrice12="12.0"
            takeawayPrice9="6.0"
            takeawayPrice12="10.0"
          />
          <MenuItem
            name="CEASE FIRE"
            ingredients="San Marzano Tomato, Fior Di Latte, ‘Nduja, chillis, Red Onion, topped with a drizzle of KIS’s Hot Sauce (HOT)"
            sitInPrice9="9.0"
            sitInPrice12="13.0"
            takeawayPrice9="6.5"
            takeawayPrice12="11.0"
          />
          <MenuItem
            name="THE ORCHARD COUNTY"
            ingredients="San Marzano Tomato, Fior Di Latte, Chicken, Sun-dried tomatoes, finished with Pesto Dressing"
            sitInPrice9="9.0"
            sitInPrice12="13.0"
            takeawayPrice9="6.0"
            takeawayPrice12="11.0"
          />
          <MenuItem
            name="3 LITTLE PIGS"
            ingredients="San Marzano Tomato, Fior Di Latte, Pancetta, McCreesh’s Ham & Pepperoni"
            sitInPrice9="11.0"
            sitInPrice12="15.0"
            takeawayPrice9="8.0"
            takeawayPrice12="12.0"
          />
          <MenuItem
            name="THE GREAT WHITE (NO SAUCE)"
            ingredients="Fior Di Latte, Gorgonzola, Chili Oil, Rocket, Smoked Pancetta with a Drizzle of Honey"
            sitInPrice9="11.0"
            sitInPrice12="15.0"
          />
          <MenuItem
            name="ITALIAN STALLION"
            ingredients="San Marzano Tomato, Fior Di Latte, Pepperoni, ‘Nduja, with a drizzle of Honey"
            sitInPrice9="9.0"
            sitInPrice12="13.0"
            takeawayPrice9="6.5"
            takeawayPrice12="11.0"
          />
          <MenuItem
            name="NO MEAT PLEASE"
            ingredients="San Marzano Tomato, Fior Di Latte, Red onion, Mushroom with Mixed pepper"
            sitInPrice9="8.0"
            sitInPrice12="12.0"
            takeawayPrice9="6.0"
            takeawayPrice12="10.0"
          />
          <MenuItem
            name="KIS ME"
            ingredients="San Marzano Tomato, Fior Di Latte, Pepperoni, chicken finished with Garlic & South west Sauce"
            sitInPrice9="9.0"
            sitInPrice12="13.0"
            takeawayPrice9="6.5"
            takeawayPrice12="11.0"
          />
          <MenuItem
            name="ROCKET MAN"
            ingredients="San Marzano Tomato , Fior Di Latte, Parma Ham , Rocket & Goats Cheese"
            sitInPrice9="11.0"
            sitInPrice12="15.0"
          />
          <MenuItem
            name="SMOKY & THE BANDIT"
            ingredients="BBQ Sauce , Fior Di Latte , Chicken , Smoked Pancetta & Red onion"
            sitInPrice9="9.0"
            sitInPrice12="13.0"
            takeawayPrice9="6.5"
            takeawayPrice12="11.0"
          />
          <MenuItem
            name="PORKY PINEAPPLE"
            ingredients="San Marzano Tomato , Fior Di Latte, McCreesh’s Ham & Pineapple"
            sitInPrice9="8.0"
            sitInPrice12="12.0"
            takeawayPrice9="6.0"
            takeawayPrice12="10.0"
          />
          <MenuItem
            name="CROSS BORDER"
            ingredients="San Marzano Tomato , Fior Di Latte, ‘Nduja , Chorizo & Mushroom"
            sitInPrice9="9.0"
            sitInPrice12="13.0"
            takeawayPrice9="6.5"
            takeawayPrice12="11.0"
          />
        </div>
      </div>
    </section>
  );
}

export default Menu;
