import React from "react";
import PizzaImg from "../../assets/pizza-10.JPG";

function Content() {
  return (
    <section className="bg-primary mt-24 text-center md:text-left">
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <h1 className="text-secondary text-3xl font-bold sm:text-4xl mb-8">
          Pizza, made with the freshest ingredients
        </h1>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16 items-center justify-center">
          <div className="lg:py-16 text-white">
            <article className="space-y-4">
              <p className="text-lg">
                At KIS Pizza & Coffee Bar, we take great pride in our
                pizza-making process. Our dough is crafted using the finest
                Italian flour and a complex technique that results in a highly
                digestible dough with intense flavor.
                <br />
                Every pizza we make is a labor of love that requires TIME,
                EFFORT, and CARE to ensure the perfect balance of flavors. We
                use locally-sourced ingredients from Orchard County Veg and
                McCreeshs Food, as well as premium ingredients sourced directly
                from Italy.
                <br />
                Please note that while we take great care to prevent
                cross-contamination, all of our food is produced in an
                environment that handles both nuts and gluten. If you have any
                serious allergies, please inform us so we can take extra
                precautions. Ciao Bella!
              </p>
            </article>
          </div>
          <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
            <img
              src={PizzaImg}
              alt="Pizza made with the freshest ingredients, served on a plate."
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content;
